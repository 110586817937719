<template>
    <div>
        <div v-if="authUserPermission['duplicate-material-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--7">
                <div class="row">
                    <div class="col-md-6">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-6">
                                        <h3>{{ tt('material_duplication') }}</h3>
                                    </div>
                                    <div class="col-6">
                                        <input type="text" class="form-control form-control-sm text-center border-radius-20" v-model="table.search" :placeholder="tt('search_material_duplication')" v-on:keyup="filter"/> 
                                    </div>
                                </div>
                            </div>
                            <div>
                                <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data" ref="singleTable" highlight-current-row 
                                @current-change="handleCurrentChange" v-if="!onLoad.materialDuplication">
                                    <el-table-column :label="tt('material_number_dupl')" prop="material_number" min-width="90px" sortable></el-table-column>
                                    <el-table-column :label="tt('standard_item_name')" prop="standard_item_name" min-width="110px" sortable></el-table-column>
                                    <el-table-column :label="tt('short_text')" prop="short_text" min-width="180px" sortable></el-table-column>
                                </el-table>
                                <skeleton-loading v-else/>
                            </div>
                            <div class="card-footer pb-0 ">   
                                <span class="float-left">
                                    <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                                </span>                             
                                <span class="float-right">  
                                    {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-12">
                                        <h3>{{ tt('detail_duplication') }}</h3>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="tableSelectedDetailMatDupl.data" ref="singleTable" v-if="!onLoad.detailMatDuplication">
                                    <el-table-column :label="tt('material_number_dupl')" prop="material_number" min-width="90px" sortable></el-table-column>
                                    <el-table-column :label="tt('plant_name')" prop="plant_name" min-width="110px" sortable></el-table-column>
                                    <el-table-column :label="tt('short_text')" prop="short_text" min-width="180px" sortable></el-table-column>
                                    <el-table-column :label="tt('stock_on_hand')" prop="stock" min-width="90px" sortable> 
                                        <template slot-scope="scope"> 
                                            <div style="text-align: center;">{{ scope.row.stock || 0 }}</div> 
                                        </template> 
                                    </el-table-column>
                                </el-table>
                                <skeleton-loading v-else/>
                            </div>
                            <div class="card-footer">                         
                                <div class="col text-right">
                                    {{ tt('page_info', {size: tableSelectedDetailMatDupl.total, total: tableSelectedDetailMatDupl.total}) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import materialDuplication from '@/services/materialDuplication/materialDuplication.service';

    export default {        
        data() {
            return {   
                currentRow: null,
                onLoad: {
                    materialDuplication: true,
                    detailMatDuplication: true,
                },
                loadTimeout: null,
                btnSave: {
                    showTableing: false
                },    
                form: {
                    add: true,
                    title: null,
                    show: false,
                },
                table: {
                    search: this.$route.query.search ? this.$route.query.search : '',                    
                    total: 0,
                    data: [],
                    page: [],
                },
                errorMessage: null,   
                scopeofSupply: {},
                materialGroup: {},
                tableSelectedDetailMatDupl:{
                    total: 0,
                    data: [],
                    page: [],
                },
                // selectedDetailMatDupl: [],
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
        },
        methods: {
            get() {
                let context = this;

                if(this.table.search == '' && this.$route.query.search){
                    this.table.search = this.$route.query.search
                }
                Api(context, materialDuplication.getMatDupl(this.table.page.current_page, {search: this.table.search})).onSuccess(function(response) {
                    context.table.total = response.data.data.data.total;
                    context.table.data = response.data.data.data.data;
                    context.table.page  = response.data.data.data;
                }).onError(function(error) {
                    if (error.response.status == 404) {
                        context.table.data = [];
                        context.table.total = 0;
                    }
                }).onFinish(function() {
                    context.onLoad.materialDuplication = false;
                    context.onLoad.detailMatDuplication = false;
                }).
                call()
            },
            filter() {
                if(this.table.search != this.$route.query.search){
                    clearTimeout(this.loadTimeout);
                    this.loadTimeout = setTimeout(() => {
                        this.onLoad.materialDuplication = true;
                        if(this.table.search == ''){
                            this.$router.push({ path:'/material-duplication', query:null})
                        }else{
                            this.$router.push({ path: '/material-duplication', query:{search: this.table.search}})
                        }
                        this.get()
                    }, 300);
                }
            },
            handleCurrentChange(val) {
                let context = this; 
                context.currentRow = val     
                this.onLoad.detailMatDuplication = true
                Api(context, materialDuplication.getDetailMatDuplicate(val.duplicate_set, val.material_number)).onSuccess(function(response) {
                    context.tableSelectedDetailMatDupl.total = response.data.data.data.total;
                    context.tableSelectedDetailMatDupl.data = response.data.data.data.data;
                    context.tableSelectedDetailMatDupl.page = response.data.data.data;

                    // context.selectedDetailMatDupl = response.data.data.data.data
                }).onError(function(error) {          
                    if (error.response.status == 404) {
                        context.tableSelectedDetailMatDupl.total = 0;
                        context.tableSelectedDetailMatDupl.data = [];
                    }          
                    // context.selectedDetailMatDupl = []
                }).onFinish(function() {
                    context.onLoad.detailMatDuplication = false
                })
                .call()
            },
            changePage(page) {
                let context = this;
                context.onLoad.materialDuplication = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()

                    context.tableSelectedDetailMatDupl.data = []
                    // context.selectedDetailMatDupl = []
                }, 100);
            }
        }   
    };
</script>
<style></style>
    
